body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.info-list .MuiListItem-container .hidden-button-info-list {
  display: none;
}

.info-list .MuiListItem-container:hover .hidden-button-info-list {
  display: flex;
}

svg:hover #map-marker {
  fill: blue;
}

#map-marker {
  transform: translate(-50%, -50%);

  animation-play-state: paused;
}

#map-marker:hover {
  animation: spin 1.2s infinite;
  animation-play-state: running;
}

#map-marker:hover path {
  stroke: white;
  stroke-width: 1px;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) scale(1.25, 0.75);
  }
  50% {
    transform: translate(-50%, -120%) scale(1, 1);
  }
  55% {
    transform: translate(-50%, -120%) rotate(15deg);
  }
  60% {
    transform: translate(-50%, -120%) rotate(-15deg);
  }
  65% {
    transform: translate(-50%, -120%) rotate(15deg);
  }
  70% {
    transform: translate(-50%, -120%) rotate(-15deg);
  }
  100% {
    transform: translate(-50%, -50%) scale(1.25, 0.75);
  }
}

#root {
  overflow: hidden;
}
